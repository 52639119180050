@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
	padding-top:40px;
	background-color: #f3f4f4;

	.container {
		background-color: #f3f4f4;
	}

	#compare-header {
		background-color: #f3f4f4;
	}

	h2 {
		font-size:30px;
		text-align: center;
	}

	.compared-vehicle-container {
		padding-top: 20px;
		background-color: #f3f4f4;
	}

	.single-vehicle {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.show-full-list-container button {
		position: relative;
		padding: 8px 26px 8px 26px;
		color: #C74F0A;
		background-color: #fff;
	}
}