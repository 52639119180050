.Incentives {

       h1 {
              text-align: center;
              font-size:30px;
       }

       p.lead {
              font-size: 14px;
              max-width: 600px;
              margin: 0 auto;
              display: block;
              text-align: center;
       }

       .btn-ae {
              display: block;
              margin: 20px auto !important;
              max-width: 250px;
              background-color: #C74F0A;
              font-size: 14px;
              padding: 10px 40px;
              border-radius: 5px;
       }

       .IncentiveCard {
              margin-bottom: 50px;
              @media(min-width:768px) {
                     flex-basis: 24%;
              }
       }
}
