@import "src/client_customizations/scss/client_master.scss";

.hcFilterControls {
   
}
.hcFilterControls {
              position: relative;
              top:50px;
              border-radius: 5px;
              border:none;
              box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);

              p.h2 {
                     color:#000;
                     font-weight: 400;
                     margin-bottom: 30px;
              }

              .form-group {
                     label {
                            font-weight: 400;
                            color:#000;
                            margin:0 0 5px;
                     }
       
                     input[type="range"] {
                            margin:10px 0;
                     }
       
                     select {
                            margin-bottom:10px;
                     }
       
                     p {
                            margin-bottom:20px !important;
                            color: #73767B;
                            font-size: 12px;
                     }
              }

              .btn {
                     // border:none;
                     background-color:#fff;
                     // padding:0;
                     color:#003D7E;
                     position: relative;
                     font-weight: 600;
              }
               .socket-text {
                    float: left;
                }
                .socket-image {
                    float: right;
                }
       }