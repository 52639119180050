.home-chargers {
       .title-area {
              h2 {
                     font-size:30px;
              }

              p {
                     font-size: 14px;
                     max-width: 650px;
                     margin: 0 auto;
                     display: block;
                     text-align: center;

                     a {
                            font-weight: bold;
                            color:#003D7E;
                            text-decoration: underline;
                     }
              }
       }

}