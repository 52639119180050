#EVIncentives {
       background-color:#fff;
       padding:75px 0;

       .text-center {
              .btn-ae {
                     background-color:#C74F0A;
                     font-size:14px;
                     padding: 10px 30px;
              }
       }
}

#EVChargingMap {
       padding:75px 0;
       // background-color:#f3f4f4;

       h2 {
              text-align: center;
              font-size:30px;
              margin:0 0 30px;
       }

       .ChargingMap {
              background-color: #fff;
              padding:20px;

              .show-full-list-container {
                     padding-top:30px;

                     .btn {
                            position: relative;
                            padding: 8px 26px 8px 26px;
                            color: #C74F0A;
                            background-color: #fff;
                     }
              }
       }
}