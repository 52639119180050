@import "src/client_customizations/scss/client_master.scss";
.Footer {
	position: relative;
	background-color: $GenericFooter-background-color;
	padding: 170px 0 75px 0;
	color: $GenericFooter-color;
	overflow: hidden;
	.disclaimer_container {
		font-family: Arial, Helvetica;
		display: flex;
		align-items: center;
		margin-left: '5px' ;
		font-size: 10px; /* Set the font size to 10px */
		div {
			   background-color:transparent !important;
	
			   img {
					  max-width: 50%;
					  margin:0 0 15px;
			   }
	
			   p {
					  color:white;
			   }
		}
	}
	&#home, &#vehicle, &#compare{
		.arrow-down {
			border-top: 110px solid #F2F2F2;
		}
		
	}

	.arrow-down {
		position: absolute;
		left: 50%;
    		transform: translateX(-50%);
		top:0;
		color: #F2F2F2;
		width: 0;
		height: 0;
		border-top: 110px solid #fff;
		border-left: 800px solid transparent; /* check border size here! */
		border-right: 800px solid transparent; /* and here! */
	}


	// .h3 {
	// 	margin-bottom: 5px;
	// 	font-size: $GenericFooter-heading-font-size;
	// 	color: $GenericFooter-heading-color;
	// }
	// a {
	// 	transition: 0.3s all ease;
	// 	-webkit-transition: 0.3s all ease;
	// 	font-size: $GenericFooter-links-font-size;
	// 	line-height: $GenericFooter-links-line-height;
	// 	color: $GenericFooter-links-color;
	// 	&:hover {
	// 		color: $GenericFooter-links-hover-color;
	// 		padding-left: 5px;
	// 		text-decoration: underline;
	// 	}
	// }
	// .p {
	// 	color: $GenericFooter-links-color;
	// }

	ul.links-top, ul.links-bottom {
		text-align: center;
		margin:0;

		li {
			display:inline-block;
			padding-right:20px;

			&:last-child {
				&:after {
					content: '';
				}
			}

			&:after {
				content: '\2022';
				color:#fff;
				position: relative;
				left:10px;
			}

			a, span {
				color:#fff;
				font-size:16px;
			}
		}
	}

	ul.links-bottom {
		li {
			a {
				font-size:14px;
			}
		}
	}

	p.copyright {
		margin:0;
		color:#fff;
		text-align: center;
		font-size:8pt;
	}
}