@import "src/client_customizations/scss/client_master.scss";

.eventsFilterControls {
    .inputDate {
        font-size: 1rem;
        height: 40px;
        padding-left: 13px;
    }
    #events-distance {
        border-radius: 0px;
        border: 1px solid black;
    }
    #events-location {
        border-radius: 0px;
        border: 1px solid black;
    }
    .event-categories {
        .btn {
            border-radius: $EventFilter-btn-border-radius;
            background-color: $EventFilter-btn-background-color;
            color: $EventFilter-btn-color;
            &:hover {
                background-color: $EventFilter-btn-hover-background-color;
                border: $EventFilter-btn-hover-border;
                color: $EventFilter-btn-hover-color;
            }
        }
        .btn.active {
            background-color: $EventFilter-btn-active-background-color;
            border: $EventFilter-btn-active-border;
            color: $EventFilter-btn-active-color;
            &:hover {
                background-color: $EventFilter-btn-background-color;
                color: $EventFilter-btn-color;
            }
        }
    }
    #eventStartDate {
        margin-bottom: 10px;
    }
} 
