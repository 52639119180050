@import "src/client_customizations/scss/client_master.scss"; 

.charger-compatibility-warning {
  font-size: 10px;
  margin: 2em auto 0 auto;
}

.ChargerCard p {
  width: 100%;
  margin-bottom: 0;
}

.ChargerCard {
  border-radius: 5px;
  border: 1px solid #E5E5E5;
}

.ChargerCardTop, .ChargerCardBottom > * {
  padding: 8px;
}

.ChargerCardBottom {
  .btn {
    position: relative;

    &:after {
      content:"";
      width:20px;
      height:20px;
      background-image:url("../../client_customizations/assets/images/icons/link-icon.svg");
      background-repeat: no-repeat;
      background-size:20px;
      display:block;
      position: absolute;
      top:10px;
      right:10px;
    }

    &:hover {
      border-radius: 5px;
    }
  }
}

.ChargerCardBottom {
  p {
    font-size:12px;
    color:#505357;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding:5px;
  }
}

.ChargerCardTop {
  position: relative;

  .top {
    display:flex;
    justify-content: space-between;

    .image {
      max-width:75px;
    }
  }

  /* For image in top-right corner */
  img {
    padding: 0;
  }

  .badge-locally-available, .badge-fuel-type {
    position: absolute;
    display: block;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 21px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

  .badge-locally-available {
    right: 28px;
    top: 8px;
  }
}

.ChargerCardBottom {
//   border-top: $EVCard-EVCardBottom-border;
  display: flex;
  flex-direction: column;

//   > * {
//     flex-grow: 1;
//     padding-top: 10px;
//     padding-bottom: 10px;
//   }

//   div:first-child {
//     border-right: $EVCard-EVCardBottom-border;
//   }
}

.buy-charger {
    text-align: center;
}

.ChargerCardBottomWithLink {
  text-align: center;
  padding: 0 0.75rem 1rem 0.75rem;

  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

.charger-row {
  display:flex;
  margin-bottom: 5px;
    p {
      padding-left: 8px;
      color: #505357;
      font-weight: 100;
    }
}

.charger-price {
  font-weight: 600;
  font-size: 1.25rem;
  color:#626262;
}

.buy-charger {
  .btn-ae {
    width:100%;
    font-size:14px;
  }
}

@media (min-width: 576px) {
  .ChargerCardTop {
    padding: 15px 15px 10px 15px;
  }

  .ChargerCardBottom > * {
    padding: 10px 15px;
  }

  .ChargerCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}