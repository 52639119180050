@import "src/client_customizations/scss/client_master.scss"; 

.ComparedCarDetails {
  margin: 1.6rem 0;
  text-align: center;
  .btn {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    background-color: $CompareVehicles-ViewVehicle-button-color;
    color: $CompareVehicles-ViewVehicle-button-fontcolor;
    border:1px solid #ccc;
  }

  .title {
    font-weight: 500;
    font-size: 0.8rem;
    color: $darkGray;
    line-height: inherit;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0;
  }
  .afterIncentives 
  { .DetailColumn {
    border-top: 1px solid #dbdbdb;
  }}
  .row {
    display: flex;
    justify-content: center;
  
    .DetailColumn {
      top: 0.4rem;
      padding: 0.5rem 0 1.12rem 0;
      border-bottom: 1px solid #dbdbdb;
      text-align: center;
    }
  }

  .h5 {
    font-size: 24px;
    font-weight: 600;
    color: $gray;
  }
}
