

.ev-filter-controls {
       border-radius: 0px;
       border: 2px solid #E4E4E4;
       padding:20px 15px;

       p.h2 {
              color:#2B2B2B;
              font-weight: 400;
              margin-bottom: 30px;
       }

       .filter-switch {
              margin:10px 0;

              span.badge {
                  img {
                      width:25px;
                  }
              }
  
              .switch {
                  float:right;
              }
  
              .clearfix {
                  clear:both;
              }
       }

       .form-group {
              span.label-style {
                     font-weight: 400;
                     color:#2B2B2B;
                     margin:0 0 5px;
              }

              .btn-grid-container {
                     margin-top:15px;

                     .btn {
                            background-color:#fff;
                            color:#003D7E;
                     }
              }
       }

       .legal-disclaimer {
              text-align: left;
       }
}