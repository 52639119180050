@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */

	
.Header {
	// background-color: $Header-background-color;
	background-color: transparent;
	// border-bottom: $Header-border-bottom;
	background-image:url("../../../assets/images/main-header.png");
	background-size:1300px auto;
	background-position:top center;
	background-repeat: no-repeat;
	min-height: 215px;
	position: relative;

	.container {
		position: relative;
	}

	&#home {
		background-image:url("../../../assets/images/home-header.png");
		background-size:1300px auto;
		background-position:top center;
		background-repeat: no-repeat;
		min-height: 315px;
	}

	&#vehicle, &#compare{
		background-color: #f3f4f4;
	}

	.language-toggle {
		position: absolute;
		top:24px;
		right:0;

		ul {
			list-style-type: none;

			li {
				display:inline-block;
				border-right:1px solid #fff;

				&:last-child {
					border-right:none;
				}

				.btn-lang {
					background-color:transparent;
					color:#fff;
					border:none;
					text-transform: uppercase;
					font-weight: 600;
					
					&.active {
						color:#C74F0A;
					}
				}
			}
		}
	}
	

	a {
		color:$Header-link-color;
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 200px;
			}
		}
		.navbar-collapse {

			a {
				span {
					padding-bottom:5px;
				}
			}

			a.active {
				span {
					color: $Header-navbar-link-color;
					text-decoration: none;
					border-bottom: 2px solid #fff;
				}
			}

      .navbar-nav {
		align-items: center;
		position: relative;
		top:-35px;

		background-color: rgba(0, 0, 0, 0.8);
		z-index: 1;
		border-radius: 12px;

		@media(max-width: 1199px) {
			top:0;
		}
		@media(min-width: 992px) {
			background-color: rgba(0, 0, 0, 0.3);
		}
      }

      .dropdown-item {
        border-radius: 0;
      }
}

    .dropdown-menu {
      top: 40px;
      border-radius: 3px;
      background-color:#2D2F33;

      .dropdown-item {
        font-size: 13px;


        &.active,
        &:active {
          background-color: transparent;
          color: $Header-navbar-link-color;
	 }
	 
	 &:hover {
		background-color:transparent;
		color:#fff;

		span {
			padding-bottom:2px;
			border-bottom:2px solid #fff;
		}
	 }

      }
    }
	}
	.nav-item {
		margin: 5px;
	}
}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}



@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					padding: 7px;
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
	}
}
