

@import "src/client_customizations/scss/client_master.scss"; 

.ChargingAvailabilityWizard {
  .input-well {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    border: none;
  }

  .text-charger-availability {
    font-weight: bold;
    color:$primary-color
  }
}