

#ev-jumbotron-title {
       background-color: #f3f4f4;
       padding-top: 25px;
       h1 {
              font-size:32px;
       }

       .ev-pricepanel-buttons {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-pack: justify;
              justify-content: space-between;

              .btn-ae {
                     background-color:#fff;
                     color:#C74F0A;
                     border: 1px solid #DBDBDB;
                     border-radius: 3px;
                     padding:10px 30px
              }
       }
}
