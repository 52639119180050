@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}

.big-promise {
	width: fit-content;
	padding: 24px 12px 4px 12px;
	border-radius: 12px;
	margin: 0 auto;
	top:-180px;
	background-color: rgba(0, 0, 0, 0.3);

	h1 {
		text-align: center;
		color:#fff;
		font-size:40px;

		@media(max-width: 767px) {
			font-size:25px;
		}
	}

	p {
		text-align: center;
		color:#fff;
		font-size:16px;

		@media(max-width: 767px) {
			font-size:14px;
		}
	}
}

.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
	}
}


.link-card-container {
	@media(max-width: 575px) {
		flex-direction: column !important;
	}
}

#link-card-buttons {
	margin-top: -6rem;
	padding: 1rem 1rem 1rem 1rem;
	background: transparent;

	h2 {
		text-align: center;
		padding:0 0 20px 0;
	}

	.link-card {
		.evc-card {
			background-color:#fff;
			box-shadow: none;
			padding:30px 10px;
			border:none;

			&:hover {
				box-shadow:0 3px 10px 0 rgba(0, 0, 0, 0.25) !important;
				border:none;
				color:#000;

				p {
					color:#000;
				}
			}

			img {
				margin-bottom: 15px;
			}

			p {
				font-size:16px;
			}
		}
	}
}

#HomepageVehiclesCarousel {
	.title-area {
		h2 {
			font-size: 30px;
			text-align: center;
		}

		p {
			font-size:14px;
			max-width:600px;
			margin:0 auto;
			display: block;
			text-align: center;
		}

		.btn-ae {
			display:block;
			margin:20px auto !important;
			max-width:250px;
			background-color:#C74F0A;
			font-size:14px;
			padding:10px;
			border-radius: 5px;
		}
	}
}

#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}

#HomepageIncentives {
	background-color:#F2F2F2;
	padding:75px 0;

	.title-area {
		h3 {
			font-size: 30px;
			text-align: center;
		}

		p {
			font-size:14px;
			max-width:600px;
			margin:0 auto;
			display: block;
			text-align: center;
		}

		.btn-ae {
			display:block;
			margin:20px auto !important;
			max-width:250px;
			background-color:#C74F0A;
			font-size:14px;
			padding:10px;
			border-radius: 5px;
		}
	}
}
