@import "src/client_customizations/scss/client_master.scss";

.ChargingMap {
	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}

	.MapControlPanel {
		.map-controls {
			.form-group {
				position: relative;

				#input-zipcode-for-charging-map {
					padding:10px;
					height:50px;
					padding-right:50px;
				}

				#update-zipcode-button {
					position: absolute;
					top:5px;
					right:5px;
					text-indent: -9999px;
					background-image:url("../../client_customizations/assets/images/icons/search-icon-white.svg");
					background-repeat: no-repeat;
					background-position:7px 6px;
					width:40px;
					height:40px;
				}
			}
		}
	}

	.ChargingStationsList {
		padding-top:50px;
		.btn {
			color:#C74F0A;
		}
	}

	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-map-container-border;
		border-radius: 4px;
		a {
			padding: 8px 0;
		}
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-legend-background;
		border: $ChargingMap-legend-border;
		border-radius: $ChargingMap-legend-border-radius;
		font-size: $ChargingMap-legend-font-size;
		.charger-type {
			position: relative;
			padding-right: 2rem;
			margin-bottom: 1rem;
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}

.input-group-text {
				background-color: #fff;
				border-left:none;
			}
.DealerMap {

	.dealerMap-content {
		#input-zipcode-for-dealers-map {
			border-right:none;
		}
		.input-group-append {
			.input-group-text {
				background-color: #fff;
				border-left:none;
			}
		}
	}

	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-dealer-map-container-border;
		border-radius: $ChargingMap-dealer-map-container-border-radius;
		a {
			padding: 8px 0;
		}
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-dealer-legend-background;
		border: $ChargingMap-dealer-legend-border;
		border-radius: $ChargingMap-dealer-legend-border-radius;
		padding: 15px;
		font-size: 0.75rem;
		max-width: 199px;
		margin-bottom: 10px;
		position: relative;
		padding-right: 2rem;
		margin-bottom: 1rem;
		.charger-type {
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
	.show-full-list-container {
		margin: 40px 0 25px 0;
		text-align: center;
	}
}
.Map {
	overflow: hidden;
}
.locationCardHolder {
	margin-top: 20px;
}
