

.MatchScoreOptions {
       border-radius: 0px;
       border: 2px solid #E4E4E4;
       padding:20px 15px;

       p.h2 {
              color:#000;
              font-weight: 400;
              margin-bottom: 30px;
       }

       .form-group {
              label {
                     font-weight: 400;
                     color:#000;
                     margin:0 0 5px;
              }

              input[type="range"] {
                     margin:10px 0;
              }

              select {
                     margin-bottom:10px;
              }

              p {
                     margin-bottom:20px !important;
                     color: #73767B;
                     font-size: 12px;
              }
       }

       .btn.btn-sm {
              border:none;
              background-color:#fff;
              padding:0;
              color:#003D7E;
              position: relative;

              &:after {
                     content:"";
                     position: absolute;
                     top:6px;
                     right:-15px;
                     width:9px;
                     height:9px;
                     display:block;
                     background-repeat: no-repeat;
                     background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMjE5NjcgMC4xNjczNjhDMC41MTI1NjMgLTAuMDU1Nzg5MiAwLjk4NzQzNyAtMC4wNTU3ODkyIDEuMjgwMzMgMC4xNjczNjhMNS43ODAzMyAzLjU5NTk0QzYuMDczMjIgMy44MTkxIDYuMDczMjIgNC4xODA5IDUuNzgwMzMgNC40MDQwNkwxLjI4MDMzIDcuODMyNjNDMC45ODc0MzcgOC4wNTU3OSAwLjUxMjU2MyA4LjA1NTc5IDAuMjE5NjcgNy44MzI2M0MtMC4wNzMyMjMzIDcuNjA5NDggLTAuMDczMjIzMyA3LjI0NzY3IDAuMjE5NjcgNy4wMjQ1MUw0LjE4OTM0IDRMMC4yMTk2NyAwLjk3NTQ5Qy0wLjA3MzIyMzMgMC43NTIzMzMgLTAuMDczMjIzMyAwLjM5MDUyNCAwLjIxOTY3IDAuMTY3MzY4WiIgZmlsbD0iIzIwNTY5NCIvPgo8L3N2Zz4K")
              }
       }
}