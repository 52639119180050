#ev-jumbotron-details {
       .show-full-list-container {
              text-align: left;

              button {
                     position: relative;
                     // text-transform: uppercase;
                     padding:8px 40px 8px 26px;
                     color:#C74F0A;

                     &.active {
                            &:after {
                                   // background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzJDNjFBRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05LjUsNS4zTDguMyw2LjVMNSwzTDEuNyw2LjVMMC41LDUuM0w1LDAuNUw5LjUsNS4zeiIvPgo8L3N2Zz4K")
                            }
                     }

                     &:after {
                            content:"";
                            width:20px;
                            height:20px;
                            // background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMS43MzUyOUwxLjY2NjY3IDAuNUw1IDQuMDI5NDFMOC4zMzMzMyAwLjVMOS41IDEuNzM1MjlMNSA2LjVMMC41IDEuNzM1MjlaIiBmaWxsPSIjMkM2MUFFIi8+Cjwvc3ZnPgo=");
                            background-repeat: no-repeat;
                            background-size:12px auto;
                            display:block;
                            position: absolute;
                            top:16px;
                            right:10px;
                     }
              }
       }
}

.graph-areas {
       .show-full-list-container {

              padding-top:30px;

              button {
                     position: relative;
                     // text-transform: uppercase;
                     padding:8px 26px 8px 26px;
                     color:#C74F0A;
                     background-color: #fff;

                     &.active {
                            &:after {
                                   // background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzJDNjFBRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05LjUsNS4zTDguMyw2LjVMNSwzTDEuNyw2LjVMMC41LDUuM0w1LDAuNUw5LjUsNS4zeiIvPgo8L3N2Zz4K")
                            }
                     }

                     &:after {
                            content:"";
                            width:20px;
                            height:20px;
                            // background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMS43MzUyOUwxLjY2NjY3IDAuNUw1IDQuMDI5NDFMOC4zMzMzMyAwLjVMOS41IDEuNzM1MjlMNSA2LjVMMC41IDEuNzM1MjlaIiBmaWxsPSIjMkM2MUFFIi8+Cjwvc3ZnPgo=");
                            background-repeat: no-repeat;
                            background-size:12px auto;
                            display:block;
                            position: absolute;
                            top:16px;
                            right:10px;
                     }
              }
       }
}

#CostOfOwnership {
       background-color:#f3f4f4;
       padding:75px 0;
       .title-area {
              .btn-ae {
                     background-color: #fff;
                     color: #C74F0A;
                     border: 1px solid #DBDBDB;
                     border-radius: 3px;
                     padding: 10px 30px;
              }
       }
}

.cost-btn-ae {
       background-color: #fff;
        color: #C74F0A;
        border: 1px solid #DBDBDB;
        border-radius: 3px;
        padding: 10px 30px;
 }

.CalcPanels {
       .btn.active {
              background-color:#fff;
              border:2px solid #ccc;
       }
}